var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "50%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-list-item",
                _vm._g({}, on),
                [
                  _c("v-list-item-title", [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c("img", {
                        attrs: {
                          width: "35",
                          height: "35",
                          src: _vm.customAppIcon(
                            _vm.connectorData.appShortName
                          ),
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "ml-3 font-weight-bold subtitle-2 secondary--text",
                        },
                        [_vm._v(_vm._s(_vm.connectorData.appName))]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.getDataDialog,
        callback: function ($$v) {
          _vm.getDataDialog = $$v
        },
        expression: "getDataDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.connectorData
            ? _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center pt-3 w-100",
                    },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c("img", {
                          attrs: {
                            width: "35",
                            height: "35",
                            src: _vm.customAppIcon(
                              _vm.connectorData.appShortName
                            ),
                          },
                        }),
                        _c("span", { staticClass: "ml-2 subtitle-2" }, [
                          _vm._v(_vm._s(_vm.connectorData.appName)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        _vm._l(
                          _vm.databaseConnectionSecrets,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-flex align-center",
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "font-weight-bold text-uppercase secondary--text",
                                  domProps: {
                                    innerHTML: _vm._s(item.itemName),
                                  },
                                }),
                                _vm._v(" : "),
                                item.hideSecret
                                  ? _c("span", [_vm._v("••••••••")])
                                  : _c("span", [
                                      _c("span", [_vm._v(_vm._s(item.data))]),
                                    ]),
                                _c("copy-to-clipboard", {
                                  attrs: {
                                    textToCopy: item.data,
                                    buttonClass: "caption font-weight-bold",
                                    buttonColor: "primary",
                                    buttonName: "copy to clipboard",
                                    iconButton: true,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.selectedConnectorOption,
                        callback: function ($$v) {
                          _vm.selectedConnectorOption = $$v
                        },
                        expression: "selectedConnectorOption",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Connect from Nuvolos",
                          value: _vm.connectorOptions.FROM_NUVOLOS,
                        },
                      }),
                      _c(
                        "v-card",
                        {
                          attrs: {
                            elevation:
                              _vm.selectedConnectorOption ===
                              _vm.connectorOptions.FROM_NUVOLOS
                                ? 2
                                : 0,
                            disabled:
                              _vm.selectedConnectorOption ===
                              _vm.connectorOptions.FROM_LOCAL_COMPUTER,
                            outlined: "",
                          },
                        },
                        [
                          _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "v-banner",
                                  { attrs: { "two-line": "" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: {
                                          slot: "icon",
                                          color: "white",
                                          size: "60",
                                        },
                                        slot: "icon",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              icon: "info",
                                              color: "info",
                                            },
                                          },
                                          [_vm._v("info")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "info--text subtitle-2" },
                                      [
                                        _vm._v(
                                          " Paste the code shown below into a running " +
                                            _vm._s(_vm.connectorData.appName) +
                                            " session in Nuvolos to execute your query. "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between mt-5",
                                    staticStyle: {
                                      "background-color":
                                        "rgba(23, 168, 193, 0.1)",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mb-0 mt-0 ml-2 font-weight-bold caption",
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.54)",
                                        },
                                      },
                                      [_vm._v("Nuvolos Connector")]
                                    ),
                                    _c("copy-to-clipboard", {
                                      attrs: {
                                        textToCopy:
                                          _vm.connectorData.nuvolosConnector,
                                        buttonClass: "font-weight-bold caption",
                                        buttonColor: "secondary",
                                        isTextButton: true,
                                        buttonName: "copy to clipboard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("CodeHighlight", {
                                  attrs: {
                                    language: _vm.connectorData.language,
                                    code: _vm.connectorData.nuvolosConnector,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-radio", {
                        staticClass: "mt-7",
                        attrs: {
                          label: "Connect from local computer",
                          value: _vm.connectorOptions.FROM_LOCAL_COMPUTER,
                        },
                      }),
                      _c(
                        "v-card",
                        {
                          attrs: {
                            elevation:
                              _vm.selectedConnectorOption ===
                              _vm.connectorOptions.FROM_LOCAL_COMPUTER
                                ? 2
                                : 0,
                            disabled:
                              _vm.selectedConnectorOption ===
                              _vm.connectorOptions.FROM_NUVOLOS,
                            outlined: "",
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-banner",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "60",
                                      },
                                      slot: "icon",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            icon: "info",
                                            color: "info",
                                          },
                                        },
                                        [_vm._v("info")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "ol",
                                    { staticClass: "info--text" },
                                    [
                                      _vm._l(
                                        _vm.connectorData
                                          .fromLocalComputerMessage,
                                        function (line, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass:
                                                "info--text subtitle-2",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(line),
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("li", [
                                        _vm._v(
                                          " Please follow the setup steps detailed "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.connectorData
                                                .externalConfigLink,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v("here")]
                                        ),
                                        _vm._v(
                                          " to configure your local machine. "
                                        ),
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Paste the code shown below into a " +
                                            _vm._s(_vm.connectorData.appName) +
                                            " editor in your local computer to run your query."
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-space-between",
                                  staticStyle: {
                                    "background-color":
                                      "rgba(23, 168, 193, 0.1)",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mb-0 mt-0 ml-2 font-weight-bold caption",
                                      staticStyle: {
                                        color: "rgba(0, 0, 0, 0.54)",
                                      },
                                    },
                                    [_vm._v("Local Connector")]
                                  ),
                                  _c("copy-to-clipboard", {
                                    attrs: {
                                      textToCopy:
                                        _vm.connectorData
                                          .localComputerConnector,
                                      buttonClass: "font-weight-bold caption",
                                      buttonColor: "secondary",
                                      isTextButton: true,
                                      buttonName: "copy to clipboard",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("CodeHighlight", {
                                attrs: {
                                  language: _vm.connectorData.language,
                                  code: _vm.connectorData
                                    .localComputerConnector,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.getDataDialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }